import React, { useState, useEffect } from 'react';

import { Redirect, useLocation } from "react-router-dom";
import jwtDecode from 'jwt-decode';

import LoadingBlind from '../../../UI/LoadingBlind/LoadingBlind';

function decode ( token )
{
	let idObject = jwtDecode ( token.id_token );

	return {
			isLoggedIn: true,
			email: idObject.email,
			groups: idObject['cognito:groups'],
			token: token.id_token
		};
}

function parseToken ( location )
{
	return Object.assign ( ...location.replace('#','').split ( '&' ).map ( e => ( { [e.split ( '=' )[0]]: e.split ( '=' )[1] } ) ) );
}

function LoginHandler ( { setLoggedInState } )
{
	const location = useLocation ();
	const [redirectTo, setRedirectTo] = useState ( false );

	useEffect(() => {
		  (() => {
		    if (true) {
			  setLoggedInState ( decode ( parseToken ( location.hash ) ) );
		      setRedirectTo ( true );
		    }
		  })();
      }, [ location, setLoggedInState ] );

	if ( redirectTo )
	{
		return <Redirect to="/admin" />
	}
	else
	{
		return <LoadingBlind />;
	}
}

export default LoginHandler;
