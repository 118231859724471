import React from 'react';

import Moment from 'react-moment';
import 'moment-timezone';

function EditorGridField ( { item, field, rowIndex, fieldIndex, isViewLink, tabIndex, viewItem, setActiveIndex } )
{
	let display = <span>{item[field.name]}</span>;

	if ( field.type === "date" )
	{
		if ( item[field.name] !== null )
		{
			display = <Moment local format="MMM DD, YYYY" date={item[field.name]}/>;
		}
		else
		{
			display = <span>Unscheduled</span>;
		}
	}
	else if ( field.type === "boolean" )
	{
		display = <span>{item[field.name] === true ? "Yes" : "No"}</span>;
	}

	if ( isViewLink )
	{
		return <a className="dataColumn"
					href={null}
					key={"datacolumn" + fieldIndex}
					tabIndex={tabIndex}
					onClick={() => viewItem()}
					onFocus={() => setActiveIndex()}>
					{display}
				</a>
	}
	else
	{
		return <span className="dataColumn"
					key={"datacolumn" + fieldIndex}>
					{display}
				</span>
	}
}

export default EditorGridField;
