import React from 'react';

import EditorGrid from '../../../../Editor/EditorGrid/EditorGrid';
import EditorField from '../../../../Editor/EditorGrid/EditorField/EditorField';

import Auxiliary from '../../../../../Auxiliary/Auxiliary';

import EventsData from '../EventsData';

function EventsTable ( { leagueId, seasonId, token, onView } )
{
	return	 <Auxiliary>
				<EditorGrid
						token={token}
						dataSource={EventsData}
						context={{seasonId: seasonId, leagueId: leagueId}}
						showEdit={true}
						showDelete={true}
						showCreate={true}
						onView={onView}>
					<EditorField name="name"
						label="Name"
						isViewLink={true}
						showInGrid={true}
						type="text" />
					<EditorField name="date"
						label="Date"
						isViewLink={false}
						showInGrid={true}
						type="date" />
					<EditorField name="points_count_in_season"
						label="Points Count in Season"
						isViewLink={false}
						showInGrid={true}
						type="boolean" />
				</EditorGrid>
			 </Auxiliary>
}

export default EventsTable;
