import React, { useState, useEffect } from 'react';

import EditorGridView from './EditorGridView/EditorGridView';
import Auxiliary from '../../../Auxiliary/Auxiliary';

function itemEdited ( item,
						items,
						setItems,
						compareFunction )
{
	let newItems = items.filter ( i => i.id !== item.id );
	newItems = [...newItems, item].sort ( compareFunction );
	setItems ( newItems );
}

function itemCreated ( newItem, items, setItems, compareFunction, processFunction )
{
    if ( processFunction )
    {
        newItem = processFunction ( newItem );
    }
	let newItems = [...items, newItem].sort ( compareFunction );
	setItems ( newItems );
}

function itemDeleted ( deletedItem, items, setItems )
{
	let newItems = items.filter ( i => i.id !== deletedItem.id );

	setItems ( newItems );
}

function EditorGrid ( { token, dataSource, children, showEdit, showDelete, showCreate, onView, context } )
{
	const [ items, setItems ] = useState ( [] );
	const [ isLoading, setIsLoading] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		dataSource.callList ( token, context )
			.then ( response =>
				{
					let processedItems = null;

					if ( dataSource.process )
					{
						processedItems = response.data.map ( dataSource.process );
					}
					else
					{
						processedItems = response.data;
					}

					setItems ( processedItems.sort ( dataSource.compare ) );
					setError ( { isError: false, message: null } );
					setIsLoading ( false );
				} )
			.catch ( error =>
				{
					setItems ( [] );
					setError ( { isError: true, message: error.message } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ token, context, dataSource ] );

	return	 <Auxiliary>
            	<EditorGridView
					token={token}
					dataSource={dataSource}
					items={items}
					error={error}
					isLoading={isLoading}
					setItems={setItems}
					setError={setError}
					itemCreated={( newItem ) => itemCreated ( newItem, items, setItems, dataSource.compare, dataSource.process )}
					itemEdited={( item ) => itemEdited ( item, items, setItems, dataSource.compare )}
					itemDeleted={( item ) => itemDeleted ( item, items, setItems )}
					viewItem={( item ) => onView ( item )}
					children={children}
					showEdit={showEdit}
					showDelete={showDelete}
					showCreate={showCreate}
					context={context} />
			 </Auxiliary>
}

export default EditorGrid;
