import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';

import ParseFile from '../../../../../../Parser/PokerGames';
import Buttons from '../../../../../UI/Buttons/Buttons';

import './UploadResults.scss';

function appendFile ( fileData, setFileData, newData )
{
    let data = JSON.parse ( JSON.stringify ( fileData ) );

    data.push ( newData );
    setFileData ( data );
}

function flatten ( fileData )
{
    let games = [];
    let highhands = [];

    for ( var i = 0; i < fileData.length; i ++ )
    {
        games = games.concat ( fileData[i].games );

        if ( fileData[i].highHands && fileData[i].highHands.length > 0 )
        {
            if ( highhands.length === 0 )
            {
                highhands = JSON.parse ( JSON.stringify ( fileData[i].highHands ) );
            }
            else if ( highhands[0].value < fileData[i].highHand[0].value )
            {
                highhands = JSON.parse ( JSON.stringify ( fileData[i].highHands ) );
            }
            else if ( highhands[0].value === fileData[i].highHands[0].value )
            {
                highhands.concat ( JSON.parse ( JSON.stringify ( fileData[i].highHands ) ) );
            }
        }
    }

    let objGames = [];

    objGames = games.map ( ( g, i ) => ( { name: "Game " + ( i + 1 ), data: g } ) );

    return { games: objGames, highhands: highhands };
}

const UploadResults = ( { setData, setError } ) =>
{
    const [ fileData, setFileData ] = useState ( [] );

	return <div className="upload-results">
				<h4>Upload</h4>
				<p>Upload a PokerNow CSV log to view results in your browser</p>
                { ( fileData.length === 0 ? null :
                    fileData.map ( ( data, index ) => <p key={"file-" + index}>{data.games.length + " Games Uploaded"}</p> ) )
                }
				<CSVReader onDrop={(data) => appendFile ( fileData, setFileData, ParseFile ( data ) ) }
						style={{ display: "block"}}
						onError={(error) => {setError(error)}}>
						<span>Drop CSV file here or click to upload.</span>
				</CSVReader>
                <p>Click above to upload additional files before selecting NEXT below</p>
                <Buttons align="centre">
					<button onClick={() => setData ( flatten ( fileData ) )}>Next</button>
				</Buttons>
			</div>
}

export default UploadResults;
