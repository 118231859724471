import React, { useState } from 'react';

import DeleteItemDialog from './DeleteItemDialog/DeleteItemDialog';

function doDeleteItem ( item, token, dataSource, itemDeleted, setIsDeletingItem, setError, context )
{
	dataSource.callDelete ( item, token, context )
		.then ( response =>
			{
				itemDeleted ( item );
				setIsDeletingItem ( false );
				setError ( { isError: false, message: null } );
			} )
		.catch ( error =>
			{
				setError ( { isError: true, message: JSON.stringify ( error ) } );
			} );
}

function DeleteItem ( { name, token, item, dataSource, itemDeleted, tabIndex, type, display, onFocus, show, className, context } )
{
	const [ isDeletingItem, setIsDeletingItem] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	let deleteItemComponent = null;

	if ( isDeletingItem === true )
	{
		deleteItemComponent = <DeleteItemDialog
									onOK={(itemToDelete) => doDeleteItem ( itemToDelete, token, dataSource, itemDeleted, setIsDeletingItem, setError, context )}
									onCancel={() => setIsDeletingItem ( false )}
									item={item}
									name={name}
									error={error} />
	}

	if ( show === false )
	{
		return null;
	}
	else
	{
		return <span className={className}>
					{deleteItemComponent}
					{type==="button" ?
						<button tabIndex={tabIndex} className={display===true ? "shown" : "hidden"}
								onClick={() => setIsDeletingItem(true)}
								onFocus={onFocus}>Delete</button> :
						<a tabIndex={tabIndex} className={display===true ? "shown" : "hidden"}
								onClick={() => setIsDeletingItem(true)}
								onFocus={onFocus}>Delete</a>}
				</span>
	}
}

export default DeleteItem;
