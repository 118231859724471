import React, { useState } from 'react';

import Dialog from '../../../../../UI/Dialog/Dialog';
import EditorForm from '../../EditorForm/EditorForm';

const EditItemDialog = ( { item, onOK, onCancel, error, fields } ) => {
	const [editedItem, setEditedItem] = useState ( {...item} );

	let errorComponents = null;

	if ( error.isError === true )
	{
		errorComponents = <p>{JSON.stringify(error.error)}</p>;
	}

	return ( <Dialog onOK={() => onOK(editedItem)} onCancel={onCancel}>
					<h3>Edit</h3>
					{errorComponents}
					<EditorForm item={editedItem} setItem={setEditedItem} error={error} fields={fields} />
				</Dialog> );
}

export default EditItemDialog;
