import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Moment from 'react-moment';
import Chart from './Chart/Chart';
import StyleChart from './StyleChart/StyleChart';
import Place from './Place/Place';
import KnockOuts from './KnockOuts/KnockOuts';
import HighHand from './HighHand/HighHand';
import LoadingBlind from '../UI/LoadingBlind/LoadingBlind';
import ErrorDisplay from '../UI/ErrorDisplay/ErrorDisplay';

import './ViewResults.scss';

import axios from 'axios';

const ViewResults = ( { uploadedResults } ) =>
{
	const { eventId, leagueId, seasonId } = useParams ();
	const [ data, setData ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	var games = [];

	if ( data )
	{
		games =  data.games.map ( ( game, index ) => <div className="game" key={index}>
						<Chart key={"chart" + ( index + 1 )} title={"Game " + ( index + 1 )} game={data.games[index].data} />
						<StyleChart key={"style" + ( index + 1 )} game={game.data} />
						<div>

						</div>
						<div className="blocks" key={"data-" + ( index + 1 )}>
							<Place players={data.games[index].data.players} title={"Game " + ( index + 1 ) + " Results"}/>
							<KnockOuts knockOuts={data.games[index].data.knockOuts} title={"Game " + ( index + 1 ) + " Knockouts"} />
						</div>
					</div> );
	}

	useEffect ( () => {
		let mounted = true;

		if ( eventId && !uploadedResults )
		{
			setIsLoading ( true );
			axios.get ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/events/" + eventId + "/results" )
					.then ( response =>
						{
							if ( response )
							{
								setData ( response.data );
								setIsLoading ( false );
							}
							setError ( { isError: false, message: null } );
						} )
					.catch ( error =>
						{
							setError ( { isError: true, message: JSON.stringify ( error ) } );
							setIsLoading ( false );
						} );
		}
		else
		{
			setData ( uploadedResults );
		}
		return () => mounted = false;
	}, [ leagueId, seasonId, eventId, uploadedResults ] );

    let title = null;
    let date = null;

    if ( data )
    {
        title = data.name;
        date = <Moment local format="MMM DD, YYYY" date={data.date}/>;
    }

	return <div className="game-results">
                <h4>{date}</h4>
                <h1>{title}</h1>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				<h3>High Hand of the Event (+4 Points)</h3>
				{data ? <div>{data.highhands.map((highHand, index) => <HighHand key={index} highHand={highHand} /> )}</div> : null}
				{games}
			 </div>
}


export default ViewResults;
