import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import 'moment-timezone';

import LoadingBlind from '../../../../../UI/LoadingBlind/LoadingBlind';
import ErrorDisplay from '../../../../../UI/ErrorDisplay/ErrorDisplay';

import ViewResults from '../../../../../ViewResults/ViewResults';

import axios from 'axios';

const Results = ( { loggedInState } ) => {

	const { leagueId, seasonId, eventId } = useParams ();
	const [ data, setData ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
        axios.get ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/events/" + eventId + "/results" )
    			.then ( response =>
    				{
    					if ( response.data )
    					{
    						setData ( response.data );
    						setIsLoading ( false );
    					}
    					setError ( { isError: false, message: null } );
    				} )
    			.catch ( error =>
    				{
    					setError ( { isError: true, message: JSON.stringify ( error ) } );
    					setIsLoading ( false );
    				} );
		return () => mounted = false;
	}, [ leagueId, seasonId, eventId ] );

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ data === null ? null : <ViewResults uploadedResults={data} /> }
				<p className="return-link">
					<Link to={"/admin/league/" + leagueId + "/season/" + seasonId}>Return to Season</Link>
				</p>
			</div> );
}

export default Results;
