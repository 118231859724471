
import axios from 'axios';


const callGet = ( token, id, context ) =>
{
	return axios.get ( "/leagues/" + context.leagueId + "/seasons/" + context.seasonId + "/events/" + id, {
		  headers: {
		    'authorization': token
		  }
	  } );
}

const callList = ( token, context ) =>
{
	return axios.get ( "/leagues/" + context.leagueId + "/seasons/" + context.seasonId + "/events", {
		  headers: {
		    'authorization': token
		  }
	  } );
}

function callCreate ( item, token, context )
{
	let data = {
			name: item.name,
			date: new Date ( item.date ),
			points_count_in_season: item.points_count_in_season === true ? true : false
		};

	return axios.post ( "/leagues/" + context.leagueId + "/seasons/" + context.seasonId + "/events", data,
			  { headers: {
			    'authorization': token
			} } );
}

function callDelete ( item, token, context )
{
	return axios.delete ( "/leagues/" + context.leagueId + "/seasons/" + context.seasonId + "/events/" + item.id,
			  { headers: {
			    'authorization': token
			} } );
}

function callUpdate ( item, token, context )
{
	let data = {
		name: item.name,
		date: new Date ( item.date ),
		points_count_in_season: item.points_count_in_season === true ? true : false
	};

	return axios.put ( "/leagues/" + context.leagueId + "/seasons/" + context.seasonId + "/events/" + item.id, data,
			  { headers: {
			    'authorization': token
			} } );
}

const getScores = ( token, leagueId, seasonId, eventId ) =>
{
	return axios.get ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/events/" + eventId + "/scores", {
		  headers: {
		    'authorization': token
		  }
	  } );
}

function compare ( itemA, itemB )
{
	return itemA.date - itemB.date;
}

function process ( item )
{
	return { ...item, date: new Date ( Date.parse ( item.date ) ) };
}

const functions = { callGet: callGet, callList: callList, callCreate: callCreate, callDelete: callDelete, callUpdate: callUpdate, compare: compare, process: process, getScores: getScores };

export default functions;
