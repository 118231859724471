import React from "react";

import Auxiliary from '../Auxiliary/Auxiliary';

import "./NotFound.scss";

function NotFound () {
	return ( <Auxiliary>
			    <h1 className="notFoundHeader">Not Found</h1>
				<p className="notFoundMessage">The requested resource was not found</p>
			  </Auxiliary> );
}

export default NotFound;
