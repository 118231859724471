import React, { useState } from 'react';

import Dialog from '../../../../../UI/Dialog/Dialog';
import EditorForm from '../../EditorForm/EditorForm';

const CreateItemDialog = ( { title, onOK, onCancel, error, fields } ) => {
	const [createdItem, setCreatedItem] = useState ( {} );

	let errorComponents = null;

	if ( error.isError === true )
	{
		errorComponents = <p>{JSON.stringify(error.error)}</p>;
	}

	return ( <Dialog onOK={() => onOK(createdItem)} onCancel={onCancel}>
					<h3>{title}</h3>
					{errorComponents}
					<EditorForm item={createdItem} setItem={setCreatedItem} error={error} fields={fields} />
				</Dialog> );
}

export default CreateItemDialog;
