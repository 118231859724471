import React, { useState } from 'react';

import Auxiliary from '../../../../Auxiliary/Auxiliary';

import CreateItem from '../CreateItem/CreateItem';
import DeleteItem from '../DeleteItem/DeleteItem';
import EditItem from '../EditItem/EditItem';

import Buttons from '../../../../UI/Buttons/Buttons';
import LoadingBlind from '../../../../UI/LoadingBlind/LoadingBlind';
import ErrorDisplay from '../../../../UI/ErrorDisplay/ErrorDisplay';

import EditorGridField from '../EditorGridField/EditorGridField';

import './EditorGridView.scss';

const EditorGridView = ( { 	className,
							token,
							dataSource,
							items,
							isLoading,
							error,
							viewItem,
							itemCreated,
							itemEdited,
							itemDeleted,
							setItems,
							setError,
							children,
						 	showEdit,
							showDelete,
							showCreate,
						 	context } ) => {
	const [activeIndex, setActiveIndex] = useState ( 0 );

	let fields = null;

	if ( Array.isArray(children) === false )
	{
		fields = [];
		fields.push ( {...children.props } );
	}
	else
	{
		fields = children.map ( child => ({...child.props}) );
	}

	let tabPerRowCount = fields.length + 2;

	return ( <Auxiliary>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				<div className={"grid " + className}>
					{ items.map ( ( item, index ) =>
							<div className="row" key={"item-" + index}
									onMouseEnter={() => setActiveIndex(index)}
									onMouseLeave={() => setActiveIndex(-1)}>

								{fields.map ( ( field, fieldIndex ) =>
									 <EditorGridField key={"item-" + index + "-" + fieldIndex} item={item}
									 					field={field}
														rowIndex={index}
														fieldIndex={fieldIndex}
														isViewLink={field.isViewLink}
														tabIndex={index * tabPerRowCount + fieldIndex + 1}
														viewItem={() => viewItem(item)}
														setActiveIndex={() => setActiveIndex(index)} /> )
								}

								<EditItem className="actionColumn"
										tabIndex={index * tabPerRowCount + fields.length + 1}
								 		display={activeIndex === index}
										onFocus={() => setActiveIndex(index)}
   									 	token={token}
										item={item}
										name={item.name}
										dataSource={dataSource}
										itemEdited={(item) => itemEdited(item)}
										setError={setError}
										show={showEdit}
										fields={fields}
										context={context} />
								<DeleteItem className="actionColumn"
										tabIndex={index * tabPerRowCount + fields.length + 2}
								 		display={activeIndex === index}
										onFocus={() => setActiveIndex(index)}
   									 	token={token}
										item={item}
										name={item.name}
										dataSource={dataSource}
										itemDeleted={(item) => itemDeleted(item)}
										setError={setError}
										show={showDelete}
										context={context} />
							</div> ) }
				</div>
				<Buttons show={showCreate}>
					<CreateItem onFocus={() => setActiveIndex(null)}
						type="button"
						tabIndex={items.length * tabPerRowCount}
						token={token}
						label="Create New"
						dataSource={dataSource}
						itemCreated={(newItem)=>itemCreated(newItem)}
						setError={setError}
						title="Create New"
						fields={fields}
						context={context} />
				</Buttons>
			</Auxiliary> );
}

export default EditorGridView;
