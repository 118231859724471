import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Admin from './Components/Admin/Admin';
import ViewResults from './Components/ViewResults/ViewResults';
import LeagueResults from './Components/LeagueResults/LeagueResults';
import NotFound from './Components/General/NotFound/NotFound';

import './App.scss';

function App() {
	return (
		<div className="container">
  	  		<BrowserRouter>
      			<Switch>
					<Route exact path="/results/:leagueName" component={LeagueResults} />
					<Route exact path="/results/league/:leagueId/season/:seasonId/event/:eventId">
						<ViewResults />
					</Route>
					<Route path='/admin' component={Admin} />
	  				<Route path='/' component={NotFound} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
