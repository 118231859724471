import React from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';

import './EventResult.scss';

const EventResults = ( { leagueId, seasonId, event } ) =>
    	<li className="event-list-item">
			<Link to={"/results/league/" + leagueId + "/season/" + seasonId + "/event/" + event.id}>{event.name} on <Moment local format="MMM DD, YYYY" date={event.date}/></Link>
		</li>

export default EventResults;
