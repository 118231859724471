import React from 'react';

import './Footer.scss';

const Footer = ( props ) =>
    <div className="footer">
		
    </div>


export default Footer;
