import React, { useState, useEffect } from 'react';

import 'moment-timezone';

import LoadingBlind from '../../../UI/LoadingBlind/LoadingBlind';
import ErrorDisplay from '../../../UI/ErrorDisplay/ErrorDisplay';

import SeasonsData from './SeasonsData';

import './Season.scss';

const SeasonScoresTable = ( { leagueId, seasonId }) => {

	const [ stats, setStats ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

    useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		SeasonsData.getScores ( leagueId, seasonId )
			.then ( response =>
				{
                    if ( response.data )
                    {
                        let stats = response.data;

                        if ( stats )
                        {
        					setStats ( stats );
        					setError ( { isError: false, message: null } );
        					setIsLoading ( false );
                        }
                        else
                        {
                            setStats ( [] );
                            setError ( { isError: true, message: "No stats found" } );
        					setIsLoading ( false );
                        }
                    }
                    else
                    {
                        setStats ( [] );
                        setError ( { isError: true, message: "No stats found" } );
    					setIsLoading ( false );
                    }
				} )
			.catch ( error =>
				{
					setStats ( [] );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueId, seasonId ] );

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ stats === null ? null : <>
                        <table className="knockouts-table">
                			<tbody>
                				<tr>
                					<th className="knockouts-out">Player</th>
                					<th className="knockouts-time">Points</th>
                					<th className="knockouts-koby">KOs</th>
                					<th className="knockouts-koby">Rebuys</th>
                					<th className="knockouts-koby">Hands</th>
                				</tr>
                					{stats.map ( ( stat, index ) => <tr key={index}>
                							<td className="knockouts-out">{stat.name}</td>
                							<td className="knockouts-time">{stat.points}</td>
                							<td className="knockouts-koby">{stat.knockOuts}</td>
                							<td className="knockouts-koby">{stat.rebuys}</td>
                							<td className="knockouts-koby">{stat.handsDealt}</td>
                						</tr> )}
                			</tbody>
                		</table>
                    </> }
			</div> );
}

export default SeasonScoresTable;
