import React from "react";

import Auxiliary from '../../Auxiliary/Auxiliary';
import Blind from '../Blind/Blind';
import Buttons from '../Buttons/Buttons';

import "./Dialog.scss";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.keyFunction = this.keyFunction.bind(this);
  }

  keyFunction(event) {
    if (event.keyCode === 27) {
      this.props.onCancel();
    } else if (event.keyCode === 13) {
      this.props.onOK();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyFunction, false);
  }

  render() {
    const { children, onOK, onCancel } = this.props;
    return (
		<Auxiliary>
			<Blind>
		      <div id="dialog-wrapper">
		        <div id="dialog">
		          {children}
		          <Buttons>
		            <button onClick={onOK}>
		              OK
		            </button>
		            <button onClick={onCancel}>
		              Cancel
		            </button>
		          </Buttons>
		        </div>
		      </div>
			</Blind>
	  	</Auxiliary>
    );
  }
}

export default Dialog;
