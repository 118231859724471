import React from 'react';

import Auxiliary from '../../../../Auxiliary/Auxiliary';
import Form from '../../../../UI/Form/Form';
import FormRow from '../../../../UI/Form/FormRow/FormRow';
import CheckBox from '../../../../UI/CheckBox/CheckBox';

function createEditor ( field, item, setItem )
{
	if ( field.type === "date" )
	{
		return <input id={field.name}
    			type="date"
    			value={item[field.name] && item[field.name].toISOString ? item[field.name].toISOString().substr(0,10) : null}
    			onChange={ ( event ) => setItem ( { ...item, [field.name]: new Date ( event.target.value ) } ) } />;
	}
	else if ( field.type === "boolean" )
	{
		return <CheckBox id="remember" label={item[field.name] === true ? "Yes" : "No"} type="switch"
			value={item[field.name] === true ? true : false}
			onChange={ ( event ) => setItem ( { ...item, [field.name]: event.target.checked } ) }/>
	}
	else
	{
		return <input id={field.name}
			type="text"
			value={item[field.name] ? item[field.name] : ""}
			onChange={ ( event ) => setItem ( { ...item, [field.name]: event.target.value } ) } />;
	}
}

const EditorForm = ( { item, setItem, fields, error } ) => {

	let errorComponents = null;

	if ( error.isError === true )
	{
		errorComponents = <p>{JSON.stringify(error.error)}</p>;
	}

	return ( <Auxiliary>
				<Form className="formBlack">
                    { errorComponents }
					{ fields.map ( ( field, fieldIndex ) =>
						<FormRow key={fieldIndex} id={"row-" + field.name} label={field.label}>
							{createEditor ( field, item, setItem )}
						</FormRow>
					) }
				</Form>
			</Auxiliary> );
}

export default EditorForm;
