import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Block from '../../../Block/Block';

import LeaguesTable from './LeaguesTable/LeaguesTable';

import './LeaguesAdmin.scss';

function viewLeague ( item, history )
{
	history.push ( "/admin/league/" + item.id );
}

const LeaguesAdmin = ( { loggedInState } ) => {

	let history = useHistory();

	useEffect ( () => {

		let mounted = true;

		let isSuperAdmin = loggedInState.groups.includes ( "super-admin" );
		let leagueId = loggedInState.groups.find ( id => id.length > 30 );

		if ( !isSuperAdmin && leagueId )
		{
			history.push ( "/admin/league/" + leagueId );
		}

		return () => mounted = false;
	}, [ loggedInState, history ] );

	return ( <div>
			<div className="league-admin-container">
				<Block title="Leagues">
					<LeaguesTable token={loggedInState.token} onView={(league) => viewLeague(league, history)} />
				</Block>
			</div>
		</div> );
}

export default LeaguesAdmin;
