import React from 'react';

import EditorGrid from '../../../Editor/EditorGrid/EditorGrid';
import EditorField from '../../../Editor/EditorGrid/EditorField/EditorField';

import Auxiliary from '../../../../Auxiliary/Auxiliary';

import PlayersData from '../PlayersData';

function PlayersTable ( { league, token, onView } )
{
	return	 <Auxiliary>
				<EditorGrid
						token={token}
						context={{leagueId: league.id}}
						dataSource={PlayersData}
						showEdit={true}
						showDelete={true}
						showCreate={true}
						onView={onView}>
					<EditorField name="name"
						label="Name"
						isViewLink={true}
						showInGrid={true}
						type="text" />
				</EditorGrid>
			 </Auxiliary>
}

export default PlayersTable;
