import React from 'react';

import './UserDetail.scss';

const Leagues = ( { loggedInState, setLoggedInState } ) => {

	return <div className="user-detail">
				<span className="user-email">{loggedInState.email}</span>
				<button className="button-small" onClick={() => setLoggedInState({isLoggedIn: false, isLoggedOut: true, email: null, groups: null, token: null})}>Logout</button>
			</div>
}

export default Leagues;
