import React from 'react';
import Moment from 'react-moment';

import Block from '../../Block/Block';

import './KnockOuts.scss';

const Place = props => {
  	const { knockOuts } = props;

	return <Block title={props.title}>
		<table className="knockouts-table">
			<tbody>
				<tr>
					<th className="knockouts-out">Player</th>
					<th className="knockouts-time">Time</th>
					<th className="knockouts-koby">By</th>
				</tr>
					{knockOuts.map ( ( ko, index ) => <tr key={index}>
							<td className="knockouts-out">{ko.knockedOut.name}</td>
							<td className="knockouts-time"><Moment format="HH:mm">{ko.dateTime}</Moment></td>
							<td className="knockouts-koby">{ ko.by.map((player, index) => <span key={index}>{player.name}</span>) }</td>
						</tr> )}
			</tbody>
		</table>
    </Block>
}

export default Place;
