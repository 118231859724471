import React from "react";

import "./Loading.scss";

const Loading = props => (
  <div className="box">
    <div className="cssload-spin-box" />
  </div>
);

export default Loading;
