import React, { useState } from 'react';

import EditItemDialog from './EditItemDialog/EditItemDialog';

function doEditItem ( item, token, dataSource, itemEdited, setIsEditingItem, setError, context )
{
	dataSource.callUpdate ( item, token, context )
		.then ( response =>
			{
				itemEdited ( item );
				setIsEditingItem ( false );
				setError ( { isError: false, message: null } );
			} )
		.catch ( error =>
			{
				setError ( { isError: true, message: JSON.stringify ( error ) } );
			} );
}

function EditItem ( { name, token, item, dataSource, itemEdited, tabIndex, type, display, onFocus, show, className, fields, context } )
{
	const [ isEditingItem, setIsEditingItem] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	let editItemComponent = null;

	if ( isEditingItem === true )
	{
		editItemComponent = <EditItemDialog
									onOK={(itemToEdit) => doEditItem ( itemToEdit, token, dataSource, itemEdited, setIsEditingItem, setError, context )}
									onCancel={() => setIsEditingItem ( false )}
									item={item}
									error={error}
									fields={fields} />
	}

	if ( show === false )
	{
		return null;
	}
	else
	{
		return <span className={className}>
					{editItemComponent}
					{type==="button" ?
						<button tabIndex={tabIndex} className={display===true ? "shown" : "hidden"}
								onClick={() => setIsEditingItem(true)}
								onFocus={onFocus}>Edit</button> :
						<a tabIndex={tabIndex} className={display===true ? "shown" : "hidden"}
								onClick={() => setIsEditingItem(true)}
								onFocus={onFocus}>Edit</a>}
				</span>
	}
}

export default EditItem;
