import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import Moment from 'react-moment';
import 'moment-timezone';

import LoadingBlind from '../../../../UI/LoadingBlind/LoadingBlind';
import Auxiliary from '../../../../Auxiliary/Auxiliary';
import ErrorDisplay from '../../../../UI/ErrorDisplay/ErrorDisplay';
import Buttons from '../../../../UI/Buttons/Buttons';
import Block from '../../../../Block/Block';

import UploadResults from './UploadResults/UploadResults';
import ViewResults from '../../../../ViewResults/ViewResults';

import MapPlayers from './MapPlayers/MapPlayers';

import EventScoresTable from './EventScoresTable';
import EventsData from './EventsData';

import './Event.scss';

import axios from 'axios';


function onDoneMapping ( playerMap, data, setData, setIsMapped )
{
	for ( var i = 0; i < data.games.length; i ++ )
	{
		for ( var j = 0; j < data.games[i].data.players.length; j++ )
		{
			data.games[i].data.players[j].playerId=playerMap.get ( data.games[i].data.players[j].code );
		}
	}

    for ( var k = 0; k < data.highhands.length; k ++ )
	{
		data.highhands[k].player.playerId=playerMap.get ( data.highhands[k].player.code );
	}

	setData ( data );
	setIsMapped ( true );
}

function save ( token, leagueId, seasonId, eventId, data, setData, setError, setIsLoading, setIsSaved )
{
	data.seasonId = seasonId;

	axios.post ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/events/" + eventId + "/results", data,
			  { headers: {
			    'authorization': token
			} } ).then ( response =>
				{
					setData ( response.data );
					setError ( { isError: false, message: null } );
                    setIsLoading ( false );
                    setIsSaved ( true );
                } )
			.catch ( error =>
				{
					setData ( data );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
}

function loadResults ( leagueId, seasonId, eventId, setData, setError, setIsLoading, setIsMapped, setIsSaved )
{
	axios.get ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/events/" + eventId + "/results" )
			.then ( response =>
				{
					if ( response.data.length === 1 )
					{
						setData ( response.data[0] );
						setIsMapped ( true );
						setIsLoading ( false );
						setIsSaved ( true );
					}
					setError ( { isError: false, message: null } );
				} )
			.catch ( error =>
				{
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
}

const Event = ( { loggedInState } ) => {
	const { leagueId, seasonId, eventId } = useParams ();
	const [ event, setEvent ] = useState ( null );
	const [ data, setData ] = useState ( null );
	const [ isMapped, setIsMapped ] = useState ( false );
	const [ isSaved, setIsSaved ] = useState ( false );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	const { token } = loggedInState;

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		EventsData.callGet ( token, eventId, { leagueId: leagueId, seasonId: seasonId } )
			.then ( response =>
				{
					setEvent ( response.data );
					setError ( { isError: false, message: null } );
					setIsLoading ( false );
					loadResults ( leagueId, seasonId, eventId, setData, setError, setIsLoading, setIsMapped, setIsSaved );
				} )
			.catch ( error =>
				{
					setEvent ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueId, seasonId, eventId, token ] );

    let hasResults = event && event.highhands != null;

    let controls = null;

    if ( hasResults === false )
    {
        controls = ( <div className="event-upload-container">
                                { data === null ?
                                    <UploadResults setError={setError} setData={setData} />
                                    :
                                    ( isMapped === false ?
                                        <MapPlayers data={data} loggedInState={loggedInState} onDone={(playerMap) => onDoneMapping(playerMap, data, setData, setIsMapped)} /> :
                                        <ViewResults uploadedResults={data} /> )
                                }
                            </div> );
    }
    else
    {
        controls = <div className="event-scores-container"><Block title="Scores"><EventScoresTable loggedInState={loggedInState} /></Block></div>
    }

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ event === null ? null : <Auxiliary>
						<h1>{event.name}</h1>
						<h4>Date: <Moment local format="MMM DD, YYYY" date={event.date}/></h4>
						<h4>{event.points_count_in_season === true ? "Points count in season" : "Points do not count in season"}</h4>
						{controls}
						<Buttons align="centre" show={isSaved !== true && isMapped === true && data !== null}>
							<button onClick={() => save ( token, leagueId, seasonId, eventId, data, setData, setError, setIsLoading, setIsSaved )}>Save Results</button>
						</Buttons>
                        <p className="return-link">
							<Link to={"/admin/league/" + leagueId + "/season/" + seasonId + "/event/" + eventId + "/results"}>View Full Results</Link>
						</p>
						<p className="return-link">
							<Link to={"/admin/league/" + leagueId + "/season/" + seasonId}>Return to Season</Link>
						</p>
					</Auxiliary> }
			</div> );
}

export default Event;
