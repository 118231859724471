import React from 'react';

import EventResults from './EventResults/EventResults';

import SeasonScoresTable from '../../Admin/LeagueAdmin/Seasons/SeasonScoresTable';
import Block from '../../Block/Block';

import './SeasonResults.scss';

const SeasonResults = ( { leagueId, season } ) =>
    	<li className="season-list-item">
			<h2>{season.name}</h2>
            <div className="season-list-content">
                <Block title="Scores"><SeasonScoresTable leagueId={leagueId} seasonId={season.id} /></Block>
                <Block title="Games">
        			<ul>
        				{season.events.sort ( ( e1, e2 )  => new Date ( e1.eventDate ) - new Date ( e2.eventDate ) )
        					.map ( event => <EventResults key={event.eventId} leagueId={leagueId} seasonId={season.id} event={event} /> )}
        			</ul>
                </Block>
            </div>
		</li>

export default SeasonResults;
