/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { Message: peg$parseMessage },
      peg$startRuleFunction  = peg$parseMessage,

      peg$c0 = "The admin ",
      peg$c1 = peg$literalExpectation("The admin ", false),
      peg$c2 = " enqueued the game stop on next hand.",
      peg$c3 = peg$literalExpectation(" enqueued the game stop on next hand.", false),
      peg$c4 = function(player) { return { event: "ADMIN_STOP_GAME_QUEUED", player: player }; },
      peg$c5 = " canceled the game stop on next hand.",
      peg$c6 = peg$literalExpectation(" canceled the game stop on next hand.", false),
      peg$c7 = "WARNING: the admin queued the stack change for the player ",
      peg$c8 = peg$literalExpectation("WARNING: the admin queued the stack change for the player ", false),
      peg$c9 = " adding ",
      peg$c10 = peg$literalExpectation(" adding ", false),
      peg$c11 = " chips in the next hand.",
      peg$c12 = peg$literalExpectation(" chips in the next hand.", false),
      peg$c13 = function(player, value) { return { event: "ADMIN_UPDATED_STACK_ADD_QUEUED", player: player, value: value }; },
      peg$c14 = " removing ",
      peg$c15 = peg$literalExpectation(" removing ", false),
      peg$c16 = function(player, value) { return { event: "ADMIN_UPDATED_STACK_REMOVE_QUEUED", player: player, value: -value }; },
      peg$c17 = "The admin updated the player ",
      peg$c18 = peg$literalExpectation("The admin updated the player ", false),
      peg$c19 = " stack from ",
      peg$c20 = peg$literalExpectation(" stack from ", false),
      peg$c21 = " to ",
      peg$c22 = peg$literalExpectation(" to ", false),
      peg$c23 = ".",
      peg$c24 = peg$literalExpectation(".", false),
      peg$c25 = function(player, from, to) { return { event: "ADMIN_UPDATED_STACK", player: player, from: from, to: to }; },
      peg$c26 = " reseting to ",
      peg$c27 = peg$literalExpectation(" reseting to ", false),
      peg$c28 = function(player, value) { return { event: "ADMIN_UPDATED_STACK_RESET_QUEUED", player: player, stack: value }; },
      peg$c29 = "The game's big blind was changed from ",
      peg$c30 = peg$literalExpectation("The game's big blind was changed from ", false),
      peg$c31 = function(from, to) { return { event: "BIG_BLIND_CHANGE", from: from, to: to }; },
      peg$c32 = "The game's small blind was changed from ",
      peg$c33 = peg$literalExpectation("The game's small blind was changed from ", false),
      peg$c34 = function(from, to) { return { event: "SMALL_BLIND_CHANGE", from: from, to: to }; },
      peg$c35 = "Dead Small Blind",
      peg$c36 = peg$literalExpectation("Dead Small Blind", false),
      peg$c37 = function() { return { event: "DEAD_SMALL_BLIND" }; },
      peg$c38 = "IMPORTANT: ",
      peg$c39 = peg$literalExpectation("IMPORTANT: ", false),
      peg$c40 = peg$anyExpectation(),
      peg$c41 = function(message) { return { event: "IMPORTANT_MESSAGE", message: message }; },
      peg$c42 = "The player ",
      peg$c43 = peg$literalExpectation("The player ", false),
      peg$c44 = " joined the game with a stack of ",
      peg$c45 = peg$literalExpectation(" joined the game with a stack of ", false),
      peg$c46 = function(player, value) { return { event: "JOIN", player: player, stack: value }; },
      peg$c47 = " requested a seat.",
      peg$c48 = peg$literalExpectation(" requested a seat.", false),
      peg$c49 = function(player) { return { event: "REQUEST", player: player }; },
      peg$c50 = " rejected the seat request from the player ",
      peg$c51 = peg$literalExpectation(" rejected the seat request from the player ", false),
      peg$c52 = function(admin, player) { return { event: "REJECT_SEAT_REQUEST", player: player, admin: admin}; },
      peg$c53 = " canceled the seat request.",
      peg$c54 = peg$literalExpectation(" canceled the seat request.", false),
      peg$c55 = function(player) { return { event: "CANCEL_REQUEST", player: player }; },
      peg$c56 = " enqueued the removal of the player ",
      peg$c57 = peg$literalExpectation(" enqueued the removal of the player ", false),
      peg$c58 = function(admin, player) { return { event: "REMOVAL", player: player, admin: admin }; },
      peg$c59 = " stand up with the stack of ",
      peg$c60 = peg$literalExpectation(" stand up with the stack of ", false),
      peg$c61 = function(player, value) { return { event: "STAND", player: player, stack: value }; },
      peg$c62 = " sit back with the stack of ",
      peg$c63 = peg$literalExpectation(" sit back with the stack of ", false),
      peg$c64 = function(player, value) { return { event: "SIT", player: player, stack: value }; },
      peg$c65 = " quits the game with a stack of ",
      peg$c66 = peg$literalExpectation(" quits the game with a stack of ", false),
      peg$c67 = function(player, value) { return { event: "QUIT", player: player, stack: value }; },
      peg$c68 = "The admin approved the player ",
      peg$c69 = peg$literalExpectation("The admin approved the player ", false),
      peg$c70 = " participation with a stack of ",
      peg$c71 = peg$literalExpectation(" participation with a stack of ", false),
      peg$c72 = function(player, value) { return { event: "APPROVED", player: player, stack: value }; },
      peg$c73 = " changed the ID from ",
      peg$c74 = peg$literalExpectation(" changed the ID from ", false),
      peg$c75 = " because tokenenticated login.",
      peg$c76 = peg$literalExpectation(" because tokenenticated login.", false),
      peg$c77 = function(player, guest, token) { return { event: "CHANGE_ID", player: player, guest: guest, token: token }; },
      peg$c78 = function(name, code) { return { name: name, code: code }; },
      peg$c79 = "Player stacks:",
      peg$c80 = peg$literalExpectation("Player stacks:", false),
      peg$c81 = function(stacks) { return { event: "STACKS", stacks: stacks }; },
      peg$c82 = " #",
      peg$c83 = peg$literalExpectation(" #", false),
      peg$c84 = " ",
      peg$c85 = peg$literalExpectation(" ", false),
      peg$c86 = " (",
      peg$c87 = peg$literalExpectation(" (", false),
      peg$c88 = ")",
      peg$c89 = peg$literalExpectation(")", false),
      peg$c90 = " |",
      peg$c91 = peg$literalExpectation(" |", false),
      peg$c92 = function(seat, player, stack) { return { player: player, seat: seat, stack: stack }; },
      peg$c93 = "Flop:",
      peg$c94 = peg$literalExpectation("Flop:", false),
      peg$c95 = "[",
      peg$c96 = peg$literalExpectation("[", false),
      peg$c97 = ", ",
      peg$c98 = peg$literalExpectation(", ", false),
      peg$c99 = "]",
      peg$c100 = peg$literalExpectation("]", false),
      peg$c101 = function(card1, card2, card3) { return { event: "FLOP", cards: [ card1, card2, card3 ] }; },
      peg$c102 = "Turn:",
      peg$c103 = peg$literalExpectation("Turn:", false),
      peg$c104 = " [",
      peg$c105 = peg$literalExpectation(" [", false),
      peg$c106 = function(card1, card2, card3, card4) { return { event: "TURN", cards: [ card1, card2, card3, card4 ] }; },
      peg$c107 = "River:",
      peg$c108 = peg$literalExpectation("River:", false),
      peg$c109 = function(card1, card2, card3, card4, card5) { return { event: "RIVER", cards: [ card1, card2, card3, card4, card5 ] }; },
      peg$c110 = function(ordinal, suit) { return { ordinal: ordinal, suit: suit }; },
      peg$c111 = "-- starting hand #",
      peg$c112 = peg$literalExpectation("-- starting hand #", false),
      peg$c113 = "(No Limit Texas Hold'em) (dealer: ",
      peg$c114 = peg$literalExpectation("(No Limit Texas Hold'em) (dealer: ", false),
      peg$c115 = ") --",
      peg$c116 = peg$literalExpectation(") --", false),
      peg$c117 = function(number, dealer) { return { event: "HAND_START", dealer: dealer, number: number }; },
      peg$c118 = "(No Limit Texas Hold'em) (dead button) --",
      peg$c119 = peg$literalExpectation("(No Limit Texas Hold'em) (dead button) --", false),
      peg$c120 = function(number) { return { event: "HAND_START", dealer: null, number: number }; },
      peg$c121 = "-- ending hand #",
      peg$c122 = peg$literalExpectation("-- ending hand #", false),
      peg$c123 = " --",
      peg$c124 = peg$literalExpectation(" --", false),
      peg$c125 = function(number) { return { event: "HAND_END", number: number }; },
      peg$c126 = "Your hand is ",
      peg$c127 = peg$literalExpectation("Your hand is ", false),
      peg$c128 = function(card1, card2) { return { event: "YOUR_HAND", card1: card1, card2: card2 }; },
      peg$c129 = " posts a small blind of ",
      peg$c130 = peg$literalExpectation(" posts a small blind of ", false),
      peg$c131 = /^[ ]/,
      peg$c132 = peg$classExpectation([" "], false, false),
      peg$c133 = "and go all in",
      peg$c134 = peg$literalExpectation("and go all in", false),
      peg$c135 = function(player, value) { return { event: "POST_SMALL_BLIND", player: player, value: value, allIn: false }; },
      peg$c136 = function(player, value) { return { event: "POST_SMALL_BLIND", player: player, value: value, allIn: true }; },
      peg$c137 = " posts a big blind of ",
      peg$c138 = peg$literalExpectation(" posts a big blind of ", false),
      peg$c139 = function(player, value) { return { event: "POST_BIG_BLIND", player: player, value: value, allIn: false }; },
      peg$c140 = function(player, value) { return { event: "POST_BIG_BLIND", player: player, value: value, allIn: true }; },
      peg$c141 = " folds",
      peg$c142 = peg$literalExpectation(" folds", false),
      peg$c143 = function(player) { return { event: "FOLD", player: player }; },
      peg$c144 = " calls ",
      peg$c145 = peg$literalExpectation(" calls ", false),
      peg$c146 = " and go all in",
      peg$c147 = peg$literalExpectation(" and go all in", false),
      peg$c148 = function(player, value) { return { event: "CALL", player: player, value: value, allIn: false }; },
      peg$c149 = function(player, value) { return { event: "CALL", player: player, value: value, allIn: true }; },
      peg$c150 = " checks",
      peg$c151 = peg$literalExpectation(" checks", false),
      peg$c152 = function(player) { return { event: "CHECK", player: player }; },
      peg$c153 = " bets ",
      peg$c154 = peg$literalExpectation(" bets ", false),
      peg$c155 = function(player, value) { return { event: "BET", player: player, value: value, allIn: false }; },
      peg$c156 = function(player, value) { return { event: "BET", player: player, value: value, allIn: true }; },
      peg$c157 = " raises to ",
      peg$c158 = peg$literalExpectation(" raises to ", false),
      peg$c159 = function(player, value) { return { event: "RAISE", player: player, value: value, allIn: false }; },
      peg$c160 = function(player, value) { return { event: "RAISE", player: player, value: value, allIn: true }; },
      peg$c161 = " shows a ",
      peg$c162 = peg$literalExpectation(" shows a ", false),
      peg$c163 = function(player, card1, card2) { return { event: "SHOW", player: player, card1: card1, card2: card2 }; },
      peg$c164 = "Uncalled bet of ",
      peg$c165 = peg$literalExpectation("Uncalled bet of ", false),
      peg$c166 = " returned to ",
      peg$c167 = peg$literalExpectation(" returned to ", false),
      peg$c168 = function(value, player) { return { event: "UNCALLED_BET_RETURNED", player: player, value: value }; },
      peg$c169 = " collected ",
      peg$c170 = peg$literalExpectation(" collected ", false),
      peg$c171 = " from pot",
      peg$c172 = peg$literalExpectation(" from pot", false),
      peg$c173 = " with",
      peg$c174 = peg$literalExpectation(" with", false),
      peg$c175 = function(player, value) { return { event: "WIN", player: player, value: value, hand: null }; },
      peg$c176 = " from pot with ",
      peg$c177 = peg$literalExpectation(" from pot with ", false),
      peg$c178 = function(player, value, handCombination) { return { event: "WIN", player: player, value: value, hand: handCombination }; },
      peg$c179 = "The game's ante was changed from ",
      peg$c180 = peg$literalExpectation("The game's ante was changed from ", false),
      peg$c181 = function(from, to) { return { event: "ANTE_CHANGE", from: from, to: to }; },
      peg$c182 = " (combination: ",
      peg$c183 = peg$literalExpectation(" (combination: ", false),
      peg$c184 = function(handType, card1, card2, card3, card4, card5) { return { ...handType, cards: [ card1, card2, card3, card4, card5 ] }; },
      peg$c185 = "Royal Flush, ",
      peg$c186 = peg$literalExpectation("Royal Flush, ", false),
      peg$c187 = " High",
      peg$c188 = peg$literalExpectation(" High", false),
      peg$c189 = function(highCard, suit) { return { type: "ROYAL_FLUSH", suit: suit, highCard: highCard }; },
      peg$c190 = "Straight Flush, ",
      peg$c191 = peg$literalExpectation("Straight Flush, ", false),
      peg$c192 = function(highCard, suit) { return { type: "STRAIGHT_FLUSH", suit: suit, highCard: highCard }; },
      peg$c193 = "Four of a Kind, ",
      peg$c194 = peg$literalExpectation("Four of a Kind, ", false),
      peg$c195 = function(ordinal) { return { type: "FOUR_OF_A_KIND", four: ordinal }; },
      peg$c196 = "Flush, ",
      peg$c197 = peg$literalExpectation("Flush, ", false),
      peg$c198 = function(ordinal, suit) { return { type: "FLUSH", suit: suit, highCard: ordinal }; },
      peg$c199 = "Straight, ",
      peg$c200 = peg$literalExpectation("Straight, ", false),
      peg$c201 = function(ordinal) { return { type: "STRAIGHT", highCard: ordinal }; },
      peg$c202 = "Full House, ",
      peg$c203 = peg$literalExpectation("Full House, ", false),
      peg$c204 = " over ",
      peg$c205 = peg$literalExpectation(" over ", false),
      peg$c206 = function(three, two) { return { type: "FULL_HOUSE", three: three, two: two }; },
      peg$c207 = "Three of a Kind, ",
      peg$c208 = peg$literalExpectation("Three of a Kind, ", false),
      peg$c209 = function(ordinal) { return { type: "THREE_OF_A_KIND", ordinal: ordinal }; },
      peg$c210 = "Two Pair, ",
      peg$c211 = peg$literalExpectation("Two Pair, ", false),
      peg$c212 = " & ",
      peg$c213 = peg$literalExpectation(" & ", false),
      peg$c214 = function(ordinal1, ordinal2) { return { type: "TWO_PAIR", ordinal1: ordinal1, ordinal2: ordinal2 }; },
      peg$c215 = "Pair, ",
      peg$c216 = peg$literalExpectation("Pair, ", false),
      peg$c217 = function(ordinal) { return { type: "ONE_PAIR", ordinal: ordinal }; },
      peg$c218 = function(ordinal) { return { type: "HIGH_CARD", highCard: ordinal }; },
      peg$c219 = "'s",
      peg$c220 = peg$literalExpectation("'s", false),
      peg$c221 = function(ordinal) { return ordinal; },
      peg$c222 = "2",
      peg$c223 = peg$literalExpectation("2", false),
      peg$c224 = "3",
      peg$c225 = peg$literalExpectation("3", false),
      peg$c226 = "4",
      peg$c227 = peg$literalExpectation("4", false),
      peg$c228 = "5",
      peg$c229 = peg$literalExpectation("5", false),
      peg$c230 = "6",
      peg$c231 = peg$literalExpectation("6", false),
      peg$c232 = "7",
      peg$c233 = peg$literalExpectation("7", false),
      peg$c234 = "8",
      peg$c235 = peg$literalExpectation("8", false),
      peg$c236 = "9",
      peg$c237 = peg$literalExpectation("9", false),
      peg$c238 = "10",
      peg$c239 = peg$literalExpectation("10", false),
      peg$c240 = "J",
      peg$c241 = peg$literalExpectation("J", false),
      peg$c242 = "Q",
      peg$c243 = peg$literalExpectation("Q", false),
      peg$c244 = "K",
      peg$c245 = peg$literalExpectation("K", false),
      peg$c246 = "A",
      peg$c247 = peg$literalExpectation("A", false),
      peg$c248 = "\u2663",
      peg$c249 = peg$literalExpectation("\u2663", false),
      peg$c250 = "\u2665",
      peg$c251 = peg$literalExpectation("\u2665", false),
      peg$c252 = "\u2660",
      peg$c253 = peg$literalExpectation("\u2660", false),
      peg$c254 = "\u2666",
      peg$c255 = peg$literalExpectation("\u2666", false),
      peg$c256 = function(suit) { return suit; },
      peg$c257 = "c",
      peg$c258 = peg$literalExpectation("c", false),
      peg$c259 = function() { return "♣"; },
      peg$c260 = "h",
      peg$c261 = peg$literalExpectation("h", false),
      peg$c262 = function() { return "♥"; },
      peg$c263 = "s",
      peg$c264 = peg$literalExpectation("s", false),
      peg$c265 = function() { return "♠"; },
      peg$c266 = "d",
      peg$c267 = peg$literalExpectation("d", false),
      peg$c268 = function() { return "♦"; },
      peg$c269 = function(val) { return Number(val); },
      peg$c270 = /^[0-9]/,
      peg$c271 = peg$classExpectation([["0", "9"]], false, false),
      peg$c272 = " @ ",
      peg$c273 = peg$literalExpectation(" @ ", false),
      peg$c274 = "\"",
      peg$c275 = peg$literalExpectation("\"", false),

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseMessage() {
    var s0;

    s0 = peg$parseJoin();
    if (s0 === peg$FAILED) {
      s0 = peg$parseRequest();
      if (s0 === peg$FAILED) {
        s0 = peg$parseQuit();
        if (s0 === peg$FAILED) {
          s0 = peg$parseApproved();
          if (s0 === peg$FAILED) {
            s0 = peg$parseChangeId();
            if (s0 === peg$FAILED) {
              s0 = peg$parseRemovePlayer();
              if (s0 === peg$FAILED) {
                s0 = peg$parseCancelRequest();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseReject();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseStand();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseSit();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseBigBlindChange();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseSmallBlindChange();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseDeadSmallBlind();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseImportant();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseStacks();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseFlop();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseTurn();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseRiver();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseStartingHand();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseEndingHand();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseYourHand();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parsePostSmallBlind();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parsePostSmallBlindAllIn();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parsePostBigBlind();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parsePostBigBlindAllIn();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseFold();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseCall();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parseCheck();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parseBet();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parseRaise();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parseBetAllIn();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parseRaiseAllIn();
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$parseCallAllIn();
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$parseShow();
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$parseUncalled();
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$parseCollect();
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$parseCollectWin();
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$parseAdminStackQueueAdd();
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$parseAdminStackQueueRemove();
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$parseAdminStackUpdate();
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$parseAdminStackReset();
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$parseAnte();
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$parseStopGame();
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$parseCancelStopGame();
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseStopGame() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c0) {
      s1 = peg$c0;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 37) === peg$c2) {
          s3 = peg$c2;
          peg$currPos += 37;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c4(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCancelStopGame() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c0) {
      s1 = peg$c0;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 37) === peg$c5) {
          s3 = peg$c5;
          peg$currPos += 37;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c6); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c4(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAdminStackQueueAdd() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 58) === peg$c7) {
      s1 = peg$c7;
      peg$currPos += 58;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c9) {
          s3 = peg$c9;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c10); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 24) === peg$c11) {
              s5 = peg$c11;
              peg$currPos += 24;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c12); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c13(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAdminStackQueueRemove() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 58) === peg$c7) {
      s1 = peg$c7;
      peg$currPos += 58;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c14) {
          s3 = peg$c14;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c15); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 24) === peg$c11) {
              s5 = peg$c11;
              peg$currPos += 24;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c12); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c16(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAdminStackUpdate() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 29) === peg$c17) {
      s1 = peg$c17;
      peg$currPos += 29;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c18); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c19) {
          s3 = peg$c19;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c21) {
              s5 = peg$c21;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c22); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseValue();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 46) {
                  s7 = peg$c23;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c24); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c25(s2, s4, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAdminStackReset() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 58) === peg$c7) {
      s1 = peg$c7;
      peg$currPos += 58;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 13) === peg$c26) {
          s3 = peg$c26;
          peg$currPos += 13;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c27); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 24) === peg$c11) {
              s5 = peg$c11;
              peg$currPos += 24;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c12); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c28(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBigBlindChange() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 38) === peg$c29) {
      s1 = peg$c29;
      peg$currPos += 38;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c30); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c21) {
          s3 = peg$c21;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c22); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c31(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSmallBlindChange() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 40) === peg$c32) {
      s1 = peg$c32;
      peg$currPos += 40;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c33); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c21) {
          s3 = peg$c21;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c22); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c34(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDeadSmallBlind() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c35) {
      s1 = peg$c35;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c37();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseImportant() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c38) {
      s1 = peg$c38;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c39); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = [];
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        if (input.length > peg$currPos) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c40); }
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c41(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseJoin() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 33) === peg$c44) {
          s3 = peg$c44;
          peg$currPos += 33;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c45); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c46(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRequest() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 18) === peg$c47) {
          s3 = peg$c47;
          peg$currPos += 18;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c48); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c49(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseReject() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c0) {
      s1 = peg$c0;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 43) === peg$c50) {
          s3 = peg$c50;
          peg$currPos += 43;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c51); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsePlayer();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c52(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCancelRequest() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 27) === peg$c53) {
          s3 = peg$c53;
          peg$currPos += 27;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c54); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c55(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRemovePlayer() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c0) {
      s1 = peg$c0;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 36) === peg$c56) {
          s3 = peg$c56;
          peg$currPos += 36;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c57); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsePlayer();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c58(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStand() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 28) === peg$c59) {
          s3 = peg$c59;
          peg$currPos += 28;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c60); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c61(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSit() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 28) === peg$c62) {
          s3 = peg$c62;
          peg$currPos += 28;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c63); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c64(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseQuit() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 32) === peg$c65) {
          s3 = peg$c65;
          peg$currPos += 32;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c66); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c67(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseApproved() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 30) === peg$c68) {
      s1 = peg$c68;
      peg$currPos += 30;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c69); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 31) === peg$c70) {
          s3 = peg$c70;
          peg$currPos += 31;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c71); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c72(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChangeId() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePlayer();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 21) === peg$c73) {
          s3 = peg$c73;
          peg$currPos += 21;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c74); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseID();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c21) {
              s5 = peg$c21;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c22); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseID();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 29) === peg$c75) {
                  s7 = peg$c75;
                  peg$currPos += 29;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c76); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c77(s2, s4, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePlayer() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseQUOTE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseName();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseNAME_DELIMITER();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseCode();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseQUOTE();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c78(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStacks() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 14) === peg$c79) {
      s1 = peg$c79;
      peg$currPos += 14;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c80); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseStack();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseStack();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c81(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c82) {
      s1 = peg$c82;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c83); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 32) {
          s3 = peg$c84;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsePlayer();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c86) {
              s5 = peg$c86;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c87); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseValue();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s7 = peg$c88;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c89); }
                }
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c90) {
                    s8 = peg$c90;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c91); }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c92(s2, s4, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFlop() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c93) {
      s1 = peg$c93;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c94); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.charCodeAt(peg$currPos) === 32) {
        s3 = peg$c84;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c85); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.charCodeAt(peg$currPos) === 32) {
          s3 = peg$c84;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 91) {
          s3 = peg$c95;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c96); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseCard();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c97) {
              s5 = peg$c97;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c98); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseCard();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c97) {
                  s7 = peg$c97;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c98); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseCard();
                  if (s8 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 93) {
                      s9 = peg$c99;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c100); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c101(s4, s6, s8);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTurn() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c102) {
      s1 = peg$c102;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c103); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.charCodeAt(peg$currPos) === 32) {
        s3 = peg$c84;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c85); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.charCodeAt(peg$currPos) === 32) {
          s3 = peg$c84;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCard();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c97) {
            s4 = peg$c97;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c98); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCard();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c97) {
                s6 = peg$c97;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c98); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCard();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c104) {
                    s8 = peg$c104;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c105); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseCard();
                    if (s9 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 93) {
                        s10 = peg$c99;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c100); }
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c106(s3, s5, s7, s9);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRiver() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c107) {
      s1 = peg$c107;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c108); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.charCodeAt(peg$currPos) === 32) {
        s3 = peg$c84;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c85); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.charCodeAt(peg$currPos) === 32) {
          s3 = peg$c84;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCard();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c97) {
            s4 = peg$c97;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c98); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCard();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c97) {
                s6 = peg$c97;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c98); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCard();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c97) {
                    s8 = peg$c97;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c98); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseCard();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c104) {
                        s10 = peg$c104;
                        peg$currPos += 2;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c105); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseCard();
                        if (s11 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 93) {
                            s12 = peg$c99;
                            peg$currPos++;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c100); }
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c109(s3, s5, s7, s9, s11);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCard() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseOrdinal();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseSuit();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c110(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStartingHand() {
    var s0;

    s0 = peg$parseStartingHandDealer();
    if (s0 === peg$FAILED) {
      s0 = peg$parseStartingHandDeadButton();
    }

    return s0;
  }

  function peg$parseStartingHandDealer() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 18) === peg$c111) {
      s1 = peg$c111;
      peg$currPos += 18;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c112); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (input.charCodeAt(peg$currPos) === 32) {
          s4 = peg$c84;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (input.charCodeAt(peg$currPos) === 32) {
              s4 = peg$c84;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c85); }
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 34) === peg$c113) {
            s4 = peg$c113;
            peg$currPos += 34;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c114); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsePlayer();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c115) {
                s6 = peg$c115;
                peg$currPos += 4;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c116); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c117(s2, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStartingHandDeadButton() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 18) === peg$c111) {
      s1 = peg$c111;
      peg$currPos += 18;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c112); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (input.charCodeAt(peg$currPos) === 32) {
          s4 = peg$c84;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c85); }
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (input.charCodeAt(peg$currPos) === 32) {
              s4 = peg$c84;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c85); }
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 41) === peg$c118) {
            s4 = peg$c118;
            peg$currPos += 41;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c119); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c120(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEndingHand() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c121) {
      s1 = peg$c121;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c122); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c123) {
          s3 = peg$c123;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c124); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c125(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseYourHand() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 13) === peg$c126) {
      s1 = peg$c126;
      peg$currPos += 13;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c127); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseCard();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c97) {
          s3 = peg$c97;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c98); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseCard();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c128(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePostSmallBlind() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 24) === peg$c129) {
        s2 = peg$c129;
        peg$currPos += 24;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c130); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (peg$c131.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c132); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (peg$c131.test(input.charAt(peg$currPos))) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.substr(peg$currPos, 13) === peg$c133) {
              s6 = peg$c133;
              peg$currPos += 13;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c134); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = [];
              if (peg$c131.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c132); }
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                if (peg$c131.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c132); }
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c135(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePostSmallBlindAllIn() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 24) === peg$c129) {
        s2 = peg$c129;
        peg$currPos += 24;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c130); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (peg$c131.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c132); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (peg$c131.test(input.charAt(peg$currPos))) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
          }
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c133) {
              s5 = peg$c133;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c134); }
            }
            if (s5 !== peg$FAILED) {
              s6 = [];
              if (peg$c131.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c132); }
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                if (peg$c131.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c132); }
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c136(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePostBigBlind() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 22) === peg$c137) {
        s2 = peg$c137;
        peg$currPos += 22;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c138); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (peg$c131.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c132); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (peg$c131.test(input.charAt(peg$currPos))) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.substr(peg$currPos, 13) === peg$c133) {
              s6 = peg$c133;
              peg$currPos += 13;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c134); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = [];
              if (peg$c131.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c132); }
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                if (peg$c131.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c132); }
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c139(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePostBigBlindAllIn() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 22) === peg$c137) {
        s2 = peg$c137;
        peg$currPos += 22;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c138); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (peg$c131.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c132); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (peg$c131.test(input.charAt(peg$currPos))) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
          }
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c133) {
              s5 = peg$c133;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c134); }
            }
            if (s5 !== peg$FAILED) {
              s6 = [];
              if (peg$c131.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c132); }
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                if (peg$c131.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c132); }
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c140(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFold() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c141) {
        s2 = peg$c141;
        peg$currPos += 6;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c142); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c143(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCall() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c144) {
        s2 = peg$c144;
        peg$currPos += 7;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c145); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s5 = peg$c146;
            peg$currPos += 14;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c148(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCallAllIn() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c144) {
        s2 = peg$c144;
        peg$currPos += 7;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c145); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s4 = peg$c146;
            peg$currPos += 14;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c149(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCheck() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c150) {
        s2 = peg$c150;
        peg$currPos += 7;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c151); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c152(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBet() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c153) {
        s2 = peg$c153;
        peg$currPos += 6;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c154); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s5 = peg$c146;
            peg$currPos += 14;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c155(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBetAllIn() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c153) {
        s2 = peg$c153;
        peg$currPos += 6;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c154); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s4 = peg$c146;
            peg$currPos += 14;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c156(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRaise() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c157) {
        s2 = peg$c157;
        peg$currPos += 11;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c158); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s5 = peg$c146;
            peg$currPos += 14;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c159(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRaiseAllIn() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c157) {
        s2 = peg$c157;
        peg$currPos += 11;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c158); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 14) === peg$c146) {
            s4 = peg$c146;
            peg$currPos += 14;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c147); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c160(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseShow() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 9) === peg$c161) {
        s2 = peg$c161;
        peg$currPos += 9;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c162); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCard();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c97) {
            s4 = peg$c97;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c98); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCard();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 46) {
                s6 = peg$c23;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c24); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c163(s1, s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUncalled() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c164) {
      s1 = peg$c164;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c165); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 13) === peg$c166) {
          s3 = peg$c166;
          peg$currPos += 13;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c167); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsePlayer();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c168(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCollect() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c169) {
        s2 = peg$c169;
        peg$currPos += 11;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c170); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c171) {
            s4 = peg$c171;
            peg$currPos += 9;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c172); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.substr(peg$currPos, 5) === peg$c173) {
              s6 = peg$c173;
              peg$currPos += 5;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c174); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c175(s1, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCollectWin() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePlayer();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c169) {
        s2 = peg$c169;
        peg$currPos += 11;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c170); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseValue();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 15) === peg$c176) {
            s4 = peg$c176;
            peg$currPos += 15;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c177); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseHandCombination();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c178(s1, s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAnte() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 33) === peg$c179) {
      s1 = peg$c179;
      peg$currPos += 33;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c180); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseValue();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c21) {
          s3 = peg$c21;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c22); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseValue();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s5 = peg$c23;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c181(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHandCombination() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    s0 = peg$currPos;
    s1 = peg$parseHandType();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 15) === peg$c182) {
        s2 = peg$c182;
        peg$currPos += 15;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c183); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCard();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c97) {
            s4 = peg$c97;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c98); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCard();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c97) {
                s6 = peg$c97;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c98); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCard();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c97) {
                    s8 = peg$c97;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c98); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseCard();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c97) {
                        s10 = peg$c97;
                        peg$currPos += 2;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c98); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseCard();
                        if (s11 !== peg$FAILED) {
                          s12 = [];
                          s13 = peg$currPos;
                          if (input.substr(peg$currPos, 2) === peg$c97) {
                            s14 = peg$c97;
                            peg$currPos += 2;
                          } else {
                            s14 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c98); }
                          }
                          if (s14 !== peg$FAILED) {
                            s15 = peg$parseCard();
                            if (s15 !== peg$FAILED) {
                              s14 = [s14, s15];
                              s13 = s14;
                            } else {
                              peg$currPos = s13;
                              s13 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s13;
                            s13 = peg$FAILED;
                          }
                          while (s13 !== peg$FAILED) {
                            s12.push(s13);
                            s13 = peg$currPos;
                            if (input.substr(peg$currPos, 2) === peg$c97) {
                              s14 = peg$c97;
                              peg$currPos += 2;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c98); }
                            }
                            if (s14 !== peg$FAILED) {
                              s15 = peg$parseCard();
                              if (s15 !== peg$FAILED) {
                                s14 = [s14, s15];
                                s13 = s14;
                              } else {
                                peg$currPos = s13;
                                s13 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s13;
                              s13 = peg$FAILED;
                            }
                          }
                          if (s12 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 41) {
                              s13 = peg$c88;
                              peg$currPos++;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c89); }
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c184(s1, s3, s5, s7, s9, s11);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHandType() {
    var s0;

    s0 = peg$parseRoyalFlush();
    if (s0 === peg$FAILED) {
      s0 = peg$parseStraightFlush();
      if (s0 === peg$FAILED) {
        s0 = peg$parseFourOfAKind();
        if (s0 === peg$FAILED) {
          s0 = peg$parseFullHouse();
          if (s0 === peg$FAILED) {
            s0 = peg$parseFlush();
            if (s0 === peg$FAILED) {
              s0 = peg$parseStraight();
              if (s0 === peg$FAILED) {
                s0 = peg$parseThreeOfAKind();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseTwoPair();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseOnePair();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseHighCard();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseRoyalFlush() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 13) === peg$c185) {
      s1 = peg$c185;
      peg$currPos += 13;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c186); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinal();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSuitCharacter();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c187) {
            s4 = peg$c187;
            peg$currPos += 5;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c189(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStraightFlush() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c190) {
      s1 = peg$c190;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c191); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinal();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSuitCharacter();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c187) {
            s4 = peg$c187;
            peg$currPos += 5;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c192(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFourOfAKind() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c193) {
      s1 = peg$c193;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c194); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinals();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c195(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFlush() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c196) {
      s1 = peg$c196;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c197); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinal();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSuitCharacter();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c187) {
            s4 = peg$c187;
            peg$currPos += 5;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c198(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStraight() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c199) {
      s1 = peg$c199;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c200); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinal();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c187) {
          s3 = peg$c187;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c188); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c201(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFullHouse() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 12) === peg$c202) {
      s1 = peg$c202;
      peg$currPos += 12;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c203); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinals();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c204) {
          s3 = peg$c204;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c205); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOrdinals();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c206(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseThreeOfAKind() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 17) === peg$c207) {
      s1 = peg$c207;
      peg$currPos += 17;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c208); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinals();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c209(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTwoPair() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c210) {
      s1 = peg$c210;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c211); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinals();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c212) {
          s3 = peg$c212;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c213); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOrdinals();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c214(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOnePair() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c215) {
      s1 = peg$c215;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c216); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOrdinals();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c217(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHighCard() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseOrdinal();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c187) {
        s2 = peg$c187;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c188); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c218(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOrdinals() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseOrdinal();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c219) {
        s2 = peg$c219;
        peg$currPos += 2;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c220); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c221(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOrdinal() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 50) {
      s0 = peg$c222;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c223); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 51) {
        s0 = peg$c224;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c225); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 52) {
          s0 = peg$c226;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c227); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 53) {
            s0 = peg$c228;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c229); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 54) {
              s0 = peg$c230;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c231); }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 55) {
                s0 = peg$c232;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c233); }
              }
              if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 56) {
                  s0 = peg$c234;
                  peg$currPos++;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c235); }
                }
                if (s0 === peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 57) {
                    s0 = peg$c236;
                    peg$currPos++;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c237); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c238) {
                      s0 = peg$c238;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c239); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 74) {
                        s0 = peg$c240;
                        peg$currPos++;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c241); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 81) {
                          s0 = peg$c242;
                          peg$currPos++;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c243); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 75) {
                            s0 = peg$c244;
                            peg$currPos++;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c245); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 65) {
                              s0 = peg$c246;
                              peg$currPos++;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c247); }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSuit() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 9827) {
      s0 = peg$c248;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 9829) {
        s0 = peg$c250;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c251); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 9824) {
          s0 = peg$c252;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c253); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 9830) {
            s0 = peg$c254;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c255); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSuitCharacter() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseDiamondCharacter();
    if (s1 === peg$FAILED) {
      s1 = peg$parseHeartCharacter();
      if (s1 === peg$FAILED) {
        s1 = peg$parseSpadeCharacter();
        if (s1 === peg$FAILED) {
          s1 = peg$parseClubCharacter();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c256(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseClubCharacter() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 99) {
      s1 = peg$c257;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c258); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c259();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeartCharacter() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 104) {
      s1 = peg$c260;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c261); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c262();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseSpadeCharacter() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 115) {
      s1 = peg$c263;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c264); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c265();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseDiamondCharacter() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 100) {
      s1 = peg$c266;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c267); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c268();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseValue() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseValueMatch();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c269(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseValueMatch() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    if (peg$c270.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c271); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c270.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c271); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseName() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseNAME_DELIMITER();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseNAME_DELIMITER();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c40); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseCode() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseQUOTE();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseQUOTE();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c40); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseID() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = [];
    if (peg$c131.test(input.charAt(peg$currPos))) {
      s5 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s5 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c132); }
    }
    if (s5 !== peg$FAILED) {
      while (s5 !== peg$FAILED) {
        s4.push(s5);
        if (peg$c131.test(input.charAt(peg$currPos))) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c132); }
        }
      }
    } else {
      s4 = peg$FAILED;
    }
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = [];
        if (peg$c131.test(input.charAt(peg$currPos))) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c132); }
        }
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (peg$c131.test(input.charAt(peg$currPos))) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
          }
        } else {
          s4 = peg$FAILED;
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c40); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseNAME_DELIMITER() {
    var s0;

    if (input.substr(peg$currPos, 3) === peg$c272) {
      s0 = peg$c272;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c273); }
    }

    return s0;
  }

  function peg$parseQUOTE() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 34) {
      s0 = peg$c274;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c275); }
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

//module.exports = {
 // SyntaxError: peg$SyntaxError,
//  parse:       peg$parse
//};

export default peg$parse;
