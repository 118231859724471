import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import Moment from 'react-moment';
import 'moment-timezone';

import LoadingBlind from '../../../UI/LoadingBlind/LoadingBlind';
import Auxiliary from '../../../Auxiliary/Auxiliary';
import ErrorDisplay from '../../../UI/ErrorDisplay/ErrorDisplay';

import PlayersData from './PlayersData';

const Player = ( { loggedInState } ) => {
	const { leagueId, playerId } = useParams ();
	const [ player, setPlayer ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	const { apiKey, league } = loggedInState;

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		PlayersData.callGet ( { leagueId: leagueId, apiKey: apiKey }, playerId )
			.then ( response =>
				{
					setPlayer ( response.data );
					setError ( { isError: false, message: null } );
					setIsLoading ( false );
				} )
			.catch ( error =>
				{
					setPlayer ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueId, playerId, apiKey ] );

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ player === null ? null : <Auxiliary>
						<h1>{league.name + " - " + player.name}</h1>
						<h4>Created At: <Moment local format="MMM DD, YYYY hh:mm A" date={player.createdAt}/></h4>
						<h4>Last Updated: <Moment local format="MMM DD, YYYY hh:mm A" date={player.updatedAt}/></h4>
						<p className="return-link">
							<Link to={"/admin/league/" + leagueId}>Return to League</Link>
						</p>
					</Auxiliary> }
			</div> );
}

export default Player;
