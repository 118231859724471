
import axios from 'axios';

const getScores = ( leagueId, seasonId ) =>
{
	return axios.get ( "/leagues/" + leagueId + "/seasons/" + seasonId + "/scores" );
}

const callGet = ( token, id, context ) =>
{
	return axios.get ( "/leagues/" + context.leagueId + "/seasons/" + id, {
		  headers: {
		    'authorization': token
		  }
	  } );
}

const callList = ( token, context ) =>
{
	return axios.get ( "/leagues/" + context.leagueId + "/seasons", {
		  headers: {
		    'authorization': token
		  }
	  } );
}

function callCreate ( item, token, context )
{
	let data = {
			name: item.name
		};

	return axios.post ( "/leagues/" + context.leagueId + "/seasons", data,
			  { headers: {
			    'authorization': token
			} } );
}

function callDelete ( item, token, context )
{
	return axios.delete ( "/leagues/" + context.leagueId + "/seasons/" + item.id,
			  { headers: {
			    'authorization': token
			} } );
}

function callUpdate ( item, token, context )
{
	let data = {
		name: item.name
	};

	return axios.put ( "/leagues/" + context.leagueId + "/seasons/" + item.id, data,
			  { headers: {
			    'authorization': token
			} } );
}

function compare ( itemA, itemB )
{
	return itemA.name.toLowerCase ().localeCompare ( itemB.name.toLowerCase () );
}

const functions = { callGet: callGet, callList: callList, callCreate: callCreate, callDelete: callDelete, callUpdate: callUpdate, compare: compare, getScores: getScores };

export default functions;
