import React, { useState } from 'react';

import Auxiliary from '../../../../Auxiliary/Auxiliary';

import CreateItemDialog from './CreateItemDialog/CreateItemDialog';

function doCreateItem ( item, token, dataSource, itemCreated, setIsCreatingItem, setError, context )
{
	dataSource.callCreate ( item, token, context )
		.then ( response =>
			{
				itemCreated ( response.data );
				setIsCreatingItem ( false );
				setError ( { isError: false, message: null } );
			} )
		.catch ( error =>
			{
				setError ( { isError: true, message: JSON.stringify ( error ) } );
			} );
}


function CreateItem ( { label, token, dataSource, itemCreated, tabIndex, title, type, onFocus, onBlur, show, fields, context } )
{
	const [ isCreatingItem, setIsCreatingItem] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	let addItemComponent = null;

	if ( isCreatingItem === true )
	{
		addItemComponent = <CreateItemDialog
								title={title}
								onOK={(item) => doCreateItem ( item, token, dataSource, itemCreated, setIsCreatingItem, setError, context )}
								onCancel={() => setIsCreatingItem ( false )}
								error={error}
								fields={fields} />
	}

	if ( show === false )
	{
		return null;
	}
	else
	{
		return <Auxiliary>
					{addItemComponent}
					{type==="button" ?
						<button tabIndex={tabIndex} onClick={() => setIsCreatingItem(true)} onFocus={onFocus} onBlur={onBlur}>{label}</button> :
						<a href={null} tabIndex={tabIndex} onClick={() => setIsCreatingItem(true)} onFocus={onFocus} onBlur={onBlur}>{label}</a> }
				</Auxiliary>
	}
}

export default CreateItem;
