import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Heading from '../Heading/Heading';
import Footer from '../Footer/Footer';
import LoadingBlind from '../UI/LoadingBlind/LoadingBlind';
import ErrorDisplay from '../UI/ErrorDisplay/ErrorDisplay';

import SeasonResults from './SeasonResults/SeasonResults';

import LeaguesData from '../Admin/LeagueAdmin/Leagues/LeaguesData';

import './LeagueResults.scss';


function LeagueResults ( { setLoggedInState } )
{
	const { leagueName } = useParams ();
	const [ league, setLeague ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		LeaguesData.callFind ( leagueName )
			.then ( response =>
				{
					let league = response.data;

					if ( league )
					{
						setError ( { isError: false, message: null } );
						setLeague ( league );
					}
					else
					{
						setError ( { isError: true, message: "League not found" } );
						setLeague ( null );
					}

					setIsLoading ( false );
				} )
			.catch ( error =>
				{
					setLeague ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueName ] );

	let menuItems = null;

	if ( league !== null )
	{
		if ( !league.seasons )
		{
			league.seasons = [];
		}

		menuItems = league.seasons.map ( season => <SeasonResults key={season.id} leagueId={league.id} season={season} /> );
	}

	return <div>
				<Heading />
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ league === null ? null : <div className="admin-container">
					<h1>{league.name}</h1>
					<ul className="season-list">
						{menuItems}
					</ul>
				</div> }

				<Footer />
			</div>
}

export default LeagueResults;
