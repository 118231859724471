import React from "react";

import "./Buttons.scss";

function Buttons ( {children, show, align} ) {
	if ( show === false )
	{
		return null;
	}
	else
	{
		return ( <div className={"buttons " + ( align === "centre" ? "centreButtons" : "rightButtons" )}>
				    {children}
				  </div> );
	}
}

export default Buttons;
