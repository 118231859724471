
import React, { useEffect } from "react";

import LoadingBlind from '../../UI/LoadingBlind/LoadingBlind';

function Login() {
	useEffect(() => {
		window.location.href = process.env.REACT_APP_COGNITO_URL;
 	}, []);


  return (
    <LoadingBlind />
  );
}

export default Login;
