import React from 'react';

import './MapPlayer.scss';

const MapPlayer = ( { player, leaguePlayers, onChange, value } ) => {
	return <div className="map-player">
				<span>{player.name}</span>
				<select onChange={(event) => onChange(player, event.target.value)} value={value === null ? -1 : value}>
					<option value={-1}>Select a League Player</option>
					{leaguePlayers ? leaguePlayers.map ( lp => <option key={player.name + "-" + lp.id} value={lp.id}>{lp.name}</option> ) : null}
				</select>
			</div>
}

export default MapPlayer;
