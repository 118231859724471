import React from 'react';

import Auxiliary from '../../../../../Auxiliary/Auxiliary';
import Dialog from '../../../../../UI/Dialog/Dialog';

const DeleteItemDialog = ( { item, name, onOK, onCancel, error } ) => {

	let errorComponents = null;

	if ( error.isError === true )
	{
		errorComponents = <p>{JSON.stringify(error.error)}</p>;
	}

	return ( <Auxiliary>
				<Dialog onOK={() => onOK(item)} onCancel={onCancel}>
					<h3>Confirmation</h3>
					{errorComponents}
					<p>Are you sure you want to delete &quot;{name}&quot;?</p>
				</Dialog>
			</Auxiliary> );
}

export default DeleteItemDialog;
