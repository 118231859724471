import React, { useState } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Heading from '../../Components/Heading/Heading';
import Footer from '../../Components/Footer/Footer';
import Login from './Login/Login';
import LoginHandler from './Login/LoginHandler/LoginHandler';
import LeagueAdmin from './LeagueAdmin/LeagueAdmin';
import Player from './LeagueAdmin/Players/Player';
import Season from './LeagueAdmin/Seasons/Season';
import Event from './LeagueAdmin/Seasons/Events/Event';
import Results from './LeagueAdmin/Seasons/Events/Results/Results';
import LeaguesAdmin from './LeagueAdmin/Leagues/LeaguesAdmin';

import UserDetail from './UserDetail/UserDetail';

import './Admin.scss';

function Admin ( props )
{
	const [loggedInState, setLoggedInState] = useState ( { isLoggedIn: false, isLoggedOut: false } );

	if ( loggedInState.isLoggedOut === true )
	{
		return <div>
					<Heading />
					<div className="admin-container">
						<h3>You have logged out</h3>
					</div>
					<Footer />
				</div>
	}
	else if ( loggedInState.isLoggedIn === true )
	{
		return <div>
					<Heading>
						<UserDetail loggedInState={loggedInState} setLoggedInState={setLoggedInState} />
					</Heading>
					<div className="admin-container">
						<BrowserRouter>
							<Switch>
								<Route exact path="/admin/league/:leagueId/player/:playerId">
									<Player loggedInState={loggedInState} />
								</Route>
                                <Route exact path="/admin/league/:leagueId/season/:seasonId/event/:eventId/results">
									<Results loggedInState={loggedInState} />
								</Route>
								<Route exact path="/admin/league/:leagueId/season/:seasonId/event/:eventId">
									<Event loggedInState={loggedInState} />
								</Route>
								<Route exact path="/admin/league/:leagueId/season/:seasonId">
									<Season loggedInState={loggedInState} />
								</Route>
								<Route exact path="/admin/league/:leagueId">
									<LeagueAdmin loggedInState={loggedInState} />
								</Route>
								<Route path="/admin">
									<LeaguesAdmin loggedInState={loggedInState} />
								</Route>
							</Switch>
						</BrowserRouter>
					</div>
					<Footer />
				</div>
	}
	else
	{
		return <div>
					<Heading />
					<div className="admin-container">
						<BrowserRouter>
							<Switch>
								<Route exact path='/admin/login/handler'>
									<LoginHandler setLoggedInState={setLoggedInState} />
								</Route>
								<Route path='/admin'>
									<Login />
								</Route>
							</Switch>
						</BrowserRouter>
					</div>
					<Footer />
				</div>
	}
}

export default Admin;
