import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import Moment from 'react-moment';
import 'moment-timezone';

import LoadingBlind from '../../../UI/LoadingBlind/LoadingBlind';
import Auxiliary from '../../../Auxiliary/Auxiliary';
import Block from '../../../Block/Block';
import ErrorDisplay from '../../../UI/ErrorDisplay/ErrorDisplay';

import EventsTable from './Events/EventsTable/EventsTable';
import SeasonScoresTable from './SeasonScoresTable';

import SeasonsData from './SeasonsData';

import './Season.scss';

function viewEvent ( item, leagueId, seasonId, history )
{
	history.push ( "/admin/league/" + leagueId + "/season/" + seasonId + "/event/" + item.id );
}

const Season = ( { loggedInState } ) => {
	let history = useHistory();
	const { leagueId, seasonId } = useParams ();
	const [ season, setSeason ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	const { token } = loggedInState;

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		SeasonsData.callGet ( token, seasonId, { leagueId: leagueId } )
			.then ( response =>
				{
					setSeason ( response.data );
					setError ( { isError: false, message: null } );
					setIsLoading ( false );
				} )
			.catch ( error =>
				{
					setSeason ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueId, seasonId, token ] );

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ season === null ? null : <Auxiliary>
						<h1>{season.name}</h1>
						<h4>Created At: <Moment local format="MMM DD, YYYY hh:mm A" date={season.createdAt}/></h4>
						<h4>Last Updated: <Moment local format="MMM DD, YYYY hh:mm A" date={season.updatedAt}/></h4>
						<div className="season-admin-container">
							<Block title="Events">
								<EventsTable leagueId={leagueId} seasonId={seasonId} token={token} onView={(event) => viewEvent(event, leagueId, seasonId, history)} />
							</Block>
							<Block title="Scores">
                                <SeasonScoresTable leagueId={leagueId} seasonId={seasonId} />
							</Block>
						</div>
						<p className="return-link">
							<Link to={"/admin/league/" + leagueId}>Return to League</Link>
						</p>
					</Auxiliary> }
			</div> );
}

export default Season;
