import React from "react";

import './FormRow.scss';

function FormRow ( {label, id, children} ) {
	return ( <p className="formRow">
				<span labelfor={id}>{label}</span>
				{children}
			</p> );
}

export default FormRow;
