import React from 'react';

import './Heading.scss';

const Heading = ( { children } ) =>
	<div>
	    <div className="heading">
			<div className="heading-logo">
				<span className="logo" />
				<h1>Thursday Night Poker</h1>
			</div>
			<div className="heading-additional">
				{children}
			</div>
	    </div>
	</div>

export default Heading;
