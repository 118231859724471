import React from "react";

import './Form.scss';

function Form ( {className, children} ) {
	return ( <div className={className}>
			    {children}
			  </div> );
}

export default Form;
