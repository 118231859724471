import React from "react";

import Blind from '../Blind/Blind';
import Loading from '../Loading/Loading';

function LoadingBlind ( { display } ) {
	return ( display === true ? <Blind>
							<Loading />
						</Blind> : null );
}

export default LoadingBlind;
