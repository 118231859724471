import React from "react";

import "./Blind.scss";

function Blind ( {children} ) {
	return ( <div className="blind">
			    {children}
			  </div> );
}

export default Blind;
