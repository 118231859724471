import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Block from '../../Block/Block';

import LoadingBlind from '../../UI/LoadingBlind/LoadingBlind';
import Auxiliary from '../../Auxiliary/Auxiliary';
import ErrorDisplay from '../../UI/ErrorDisplay/ErrorDisplay';

import SeasonsTable from './Seasons/SeasonsTable/SeasonsTable';
import PlayersTable from './Players/PlayersTable/PlayersTable';

import LeaguesData from './Leagues/LeaguesData';

import './LeagueAdmin.scss';

function viewPlayer ( item, leagueId, history )
{
	history.push ( "/admin/league/" + leagueId + "/player/" + item.id );
}

function viewSeason ( item, leagueId, history )
{
	history.push ( "/admin/league/" + leagueId + "/season/" + item.id );
}

const LeagueAdmin = ( { loggedInState } ) => {

	let history = useHistory();
	const { leagueId } = useParams ();
	const { token } = loggedInState;
	const [ league, setLeague ] = useState ( null );
	const [ isLoading, setIsLoading ] = useState ( false );
	const [ error, setError] = useState ( { isError: false, message: null } );

	useEffect ( () => {

		let mounted = true;
		setIsLoading ( true );
		LeaguesData.callGet ( token, leagueId )
			.then ( response =>
				{
					setLeague ( response.data );
					setError ( { isError: false, message: null } );
					setIsLoading ( false );
				} )
			.catch ( error =>
				{
					setLeague ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
					setIsLoading ( false );
				} );
		return () => mounted = false;
	}, [ leagueId, token ] );

	return ( <div>
				<LoadingBlind display={isLoading} />
				<ErrorDisplay error={error} />
				{ league === null ? null : <Auxiliary>
					<h1>{league.name}</h1>
					<div className="league-admin-container">
						<Block title="Seasons">
							<SeasonsTable league={league} token={token} onView={(season) => viewSeason(season, leagueId, history)} />
						</Block>
						<Block title="Players">
							<PlayersTable league={league} token={token} onView={(player) => viewPlayer(player, leagueId, history)} />
						</Block>
					</div>
				</Auxiliary> }
			</div> );
}

export default LeagueAdmin;
