import React, { useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';

import MapPlayer from './MapPlayer/MapPlayer';

import PlayersData from '../../../Players/PlayersData';
import ErrorDisplay from '../../../../../UI/ErrorDisplay/ErrorDisplay';
import Buttons from '../../../../../UI/Buttons/Buttons';

function onChange ( gamePlayer, leaguePlayerId, playerMap, setPlayerMap )
{
	var newMap = new Map(playerMap);
	newMap.set ( gamePlayer.code, leaguePlayerId );
	setPlayerMap ( newMap );
}

function buildInitialMap ( data, leaguePlayers )
{
	var map = new Map ();

	for ( var i = 0; i < data.games.length; i ++ )
	{
		for ( var j = 0; j < data.games[i].data.players.length; j++ )
		{
			var playerName = data.games[i].data.players[j].name.toLowerCase ();

			for ( var k = 0; k < leaguePlayers.length; k ++ )
			{
				var leagueName = leaguePlayers[k].name.toLowerCase ();

				if ( leagueName.indexOf ( playerName ) === 0 && !map.has ( data.games[i].data.players[j].code ) )
				{
					map.set ( data.games[i].data.players[j].code, leaguePlayers[k].id );
				}
			}
		}
	}

	return map;
}

const MapPlayers = ( { data, loggedInState, onDone } ) => {

	const { token } = loggedInState;
	const { leagueId } = useParams ();
	const [ players, setPlayers ] = useState ( [] );
	const [ playerMap, setPlayerMap ] = useState ( new Map () );
	const [ error, setError] = useState ( { isError: false, message: null } );

	useEffect ( () => {

		let mounted = true;
		PlayersData.callList ( token, { leagueId: leagueId } )
			.then ( response =>
				{
					let leaguePlayerList = response.data.sort ( ( a, b ) => a.name.toLowerCase ().localeCompare ( b.name.toLowerCase () ) );
					setPlayers ( leaguePlayerList );
					setPlayerMap ( buildInitialMap ( data, leaguePlayerList ) );
					setError ( { isError: false, message: null } );
				} )
			.catch ( error =>
				{
					setPlayers ( null );
					setError ( { isError: true, message: JSON.stringify ( error ) } );
				} );
		return () => mounted = false;
	}, [ data, leagueId, token ] );

	let playersList = [];
	var seen = {};

	for ( var i = 0; i < data.games.length; i ++ )
	{
		for ( var j = 0; j < data.games[i].data.players.length; j++ )
		{
			if ( !seen.hasOwnProperty ( data.games[i].data.players[j].code ) )
			{
				seen[data.games[i].data.players[j].code] = true;
				playersList.push ( { code: data.games[i].data.players[j].code, name: data.games[i].data.players[j].name } );
			}
		}
	}

	playersList.sort ( ( a, b ) => a.name.toLowerCase ().localeCompare ( b.name.toLowerCase () ) );

	let playersComponents = playersList.map ( player => <MapPlayer key={player.code}
															player={player}
															leaguePlayers={players}
															onChange={( gamePlayer, leaguePlayerId ) => onChange ( gamePlayer, leaguePlayerId, playerMap, setPlayerMap )}
															value={playerMap.get(player.code)} /> );

	return <div>
				<h2>Map Game Players to League Players</h2>
				<ErrorDisplay error={error} />
				{playersComponents}
				<Buttons align="centre">
					<button onClick={() => onDone ( playerMap )}>Next</button>
				</Buttons>
			</div>
}

export default MapPlayers;
